export const sunrise = require('./sunrise.png')
export const sunset = require('./sunset.png')
export const cloudy = require('./cloudy.png')
export const cloudyMorning = require('./cloudy-morning.png')
export const rainy = require('./rainy.png')
export const snowy = require('./snowy.png')
export const windSign = require('./wind-sign.png')
export const hail = require('./hail.png')
export const sun = require('./sun.png')
export const cloudyNight = require('./cloudy-night.png')
export const moon = require('./moon.png')
export const showers = require('./showers.png')
export const thunder = require('./thunder.png')
export const windy = require('./windy.png')
export const tornado = require('./tornado.png')